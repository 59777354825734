<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills class="pl-2">
            <b-nav-item :to="`/factors/${$route.params.id}`" exact exact-active-class="active">Factor</b-nav-item>
            <b-nav-item :to="`/factors/${$route.params.id}/attributes`" exact exact-active-class="active">Attributes</b-nav-item>
            <b-nav-item :to="`/factors/${$route.params.id}/events`" exact exact-active-class="active">Events</b-nav-item>
        </b-nav>
        <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
    </b-card-header>
    <b-card-body v-if="links && $store.state.claims">
        <b-table id="table" outlined hover :items="links.items.map(link => $store.state.claims.items.find(claim => claim.id === link.claim))" :fields="fields" primary-key="id" sort-by="created_at" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/attributes/${item.id}`)" class="mb-0" show-empty empty-text="This factor has no attributes." empty-filtered-text="No attributes match your search. If more attributes are available you can load them by clicking 'Load More' below.">
            <template #cell(status)="data">
                <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
            </template>
            <template #cell(actions)="data">
                <b-button v-if="['PENDING','ENABLED'].includes(data.item.status)" variant="outline-danger" size="sm" :disabled="['PENDING','LOCKED'].includes(data.item.status)" v-on:click="$emit('disable', 'claim', data.item.id)">Disable</b-button>
                <b-button v-else variant="outline-success" size="sm" :disabled="['PENDING','LOCKED'].includes(data.item.status)" v-on:click="$emit('enable', 'claim', data.item.id)">Enable</b-button>
            </template>
        </b-table>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="outline-primary" v-on:click="$emit('load', 'links')">Refresh</b-button>
        <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
        <b-button v-if="links" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max(( rows ?? links.items.length) - page * 10, 0)">Next</b-button>
        <b-button v-if="links?.nextToken" variant="outline-primary" v-on:click="$emit('next', 'links')" class="ml-2" :disabled="loading_more">Load More</b-button>
    </b-card-body>
    <b-card-footer v-if="links?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ links.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
 const FIELDS = [
    {
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'value',
        label: 'Value',
        sortable: true
    },
    {
        key: 'subtype',
        label: 'Subtype',
        sortable: true,
        formatter: value => SUBTYPES[value]
    },
    {
        key: 'score',
        label: 'Score',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    },
    {
        key: 'actions',
        label: 'Actions',
        sortable: false
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};
const SUBTYPES = {
    'string': 'String',
    'number': 'Number',
    'boolean': 'Boolean',
    'url': 'URL',
    'json': 'JSON',
    'string:oidc1:name': 'Name',
    'string:oidc1:given_name': 'Given Name',
    'string:oidc1:family_name': 'Family Name',
    'string:oidc1:middle_name': 'Middle Name',
    'string:oidc1:nickname': 'Nickname',
    'string:oidc1:preferred_username': 'Preferred Username',
    'url:oidc1:profile': 'Profile',
    'url:oidc1:picture': 'Picture',
    'url:oidc1:website': 'Website',
    'string:oidc1:email': 'Email',
    'string:oidc1:gender': 'Gender',
    'string:oidc1:birthdate': 'Birthdate',
    'string:oidc1:zoneinfo': 'Time Zone',
    'string:oidc1:locale': 'Locale',
    'string:oidc1:phone_number': 'Phone Number',
    'string:oidc1:address:formatted': 'Formatted Address',
    'string:oidc1:address:street_address': 'Street Address',
    'string:oidc1:address:locality': 'Locality',
    'string:oidc1:address:region': 'Region',
    'string:oidc1:address:postal_code': 'Postal Code',
    'string:oidc1:address:country': 'Country'
}

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'EnrollmentClaims',

    /**
     * EVENTS
     */
     emits: [ 'load', 'next', 'enable', 'disable' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        loading_more: String,
        links: Array
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else {
                if (!this.links) {
                    this.$emit('load', 'links', false);
                }
                if (!this.$store.state.claims) {
                    this.$emit('load', 'claims', true); // LOAD ALL
                } else if (this.$store.state.claims.nextToken) {
                    this.$emit('next', 'claims', this.$store.state.claims.nextToken, true); // LOAD ALL
                }
            }
        }
    }
}
</script>