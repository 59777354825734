<template>
    <b-overlay :show="loading || loading2">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading || loading2 }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/attributes.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Attribute</h3>
            </b-card-header>
            <RouterView v-slot="{ Component }">
                <component :is="Component" :filter="filter" :variant="variant" :claim="claim" :links="links" @alert="sendAlert" @login="sendLogin" @loading="setLoading" @load="sendLoad" @next="sendNext" @show="sendShow" @disable="sendDisable" @enable="sendEnable" :loading_more="loading_more"/>
            </RouterView>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * EXPORTS
 */
export default {
    
    /**
     * NAME
     */
    name: 'ClaimCard',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        loading: Boolean
    },

    /**
     * DATA
     */
    data() {
        return {
            // LINKS
            links: undefined,
            // LOADING
            loading2: undefined,
            // CLAIM
            claim: undefined,
            // LOADING (MORE)
            loading_more: false
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        return this.initialize();
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * INITIALIZE
         */
        async initialize() {
            if (!this.$store.state.session) {
                setTimeout(this.initialize, 100);
            } else if (!this.claim) {
                this.getClaim();
            }
        },

        /**
         * LOADING
         */
        setLoading(loading) {
            this.loading2 = loading;
        },

        /**
         * EVENTS
         */
        sendAlert(...args) {
            this.$emit('alert', ...args);
        },

        sendLogin(...args) {
            this.$emit('login', ...args);
        },

        sendLoad(...args) {
            if (args[0] === 'claim') {
                this.getClaim();
            } else if (args[0] === 'links') {
                this.listLinks();
            } else {
                this.$emit('load', ...args);
            }
        },

        sendNext(...args) {
            if (args[0] === 'links') {
                this.nextLinks();
            } else {
                this.$emit('next', ...args);
            }
        },

        sendShow(...args) {
            this.$emit('show', ...args);
        },

        sendDisable(...args) {
            this.$emit('disable', ...args);
        },

        sendEnable(...args) {
            this.$emit('enable', ...args);
        },

        /**
         * CLAIM
         */
        async getClaim() {
            this.loading2 = 'Loading';
            try {

                // GET ENROLLMENT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getClaim($id: ID!) {
                                getClaim(id: $id) {
                                    id
                                    label
                                    attribute
                                    value
                                    score
                                    status
                                    subtype
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.claim = (await response.json()).data.getClaim;
                    // SET DATES
                    this.claim.created_at = this.claim.created_at ? new Date(this.claim.created_at) : undefined;
                    this.claim.updated_at = this.claim.updated_at ? new Date(this.claim.updated_at) : undefined;
                    this.claim.expires_at = this.claim.expires_at ? new Date(this.claim.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.claim.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain attribute.', 'Attribute', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain attribute.', 'Attribute', 'danger');
            }
            this.loading2 = undefined;
        },

        /**
         * LINKS
         */
         async listLinks() {
            this.loading2 = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listLinks($filter: TableLinkFilterInput, $limit: Int) {
                                listLinks(filter: $filter, limit: $limit) {
                                    items {
                                        id
                                        enrollment
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "claim": {
                                    "eq": "${this.$route.params.id}"
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.links = (await response.json()).data.listLinks;
                    // ADD REFRESH DATE
                    this.links.refreshed_at = new Date();
                    // NOTIFY MORE AVAILABLE
                    if (this.links.nextToken) {
                        this.$emit('alert', 'More factors are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Attribute', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain factors.', 'Attribute', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain factors.', 'Attribute', 'danger');
            }
            this.loading2 = undefined;
        },

        async nextLinks() {
            if (!this.links.nextToken) return;
            this.loading_more = true;
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query listLinks($filter: TableLinkFilterInput, $limit: Int, $nextToken: String) {
                                listLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
                                    items {
                                        id
                                        enrollment
                                    }
                                    nextToken
                                }
                            }
                        `,
                        variables: `{
                            "limit": 50,
                            "filter": {
                                "claim": {
                                    "eq": "${this.$route.params.id}"
                                }
                            },
                            "nextToken": "${this.links.nextToken}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    const links = (await response.json()).data.listLinks;
                    // ADD NEW LINKS
                    for (const link of links.items) {
                        this.links.items.push(link);
                    }
                    // SET NEXT TOKEN
                    this.links.nextToken = links.nextToken;
                    // NOTIFY MORE AVAILABLE
                    if (links.nextToken) {
                        this.$emit('alert', 'More factors are available but were not loaded due to preserve bandwidth. You can load them by clicking \'Load More\' below.', 'Attribute', 'warning', 5000);
                    }
                // EXPIRED SESSION
                } else if (response.status === '403') {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain factors.', 'Attribute', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain factors.', 'Attribute', 'danger');
            }
            this.loading_more = undefined;
        }
    }
}
</script>